/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:07f161b6-2984-4bbe-8da3-3c554d50cb3e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_X8Uy3fkrI",
    "aws_user_pools_web_client_id": "6mrl6u8a94of8qbk6bmil0hol9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://2k6vyks73badzcome4hd6idyfe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2n76bk2fifaknj3o3z4ivtgnt4",
    "aws_user_files_s3_bucket": "boditelweba18e005727b3442397d01d5ed618e42834118-qa",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
